import { useCallback, useEffect, useState, type ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Toaster,
  Typography,
  type ModalProps
} from '@matillion/component-library'
import { heap, useLDClient } from '@matillion/hub-client'
import classnames from 'classnames'

import {
  EditorType,
  LookUpType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { EmptyPlaceholder } from 'components/EmptyPlaceholder'
import {
  ParameterOverlay,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import Portal from 'components/Portal/Portal'

import { useFlags } from 'hooks/useFlags'
import { useParameterEditorState } from 'hooks/useParameterEditorState/useParameterEditorState'
import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'
import {
  dataTransferObjectComponentId,
  excelQueryComponentId,
  headerParamsParameter,
  uriParamsParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useActiveComponentInfo } from 'modules/ComponentParameters/hooks/useActiveComponentInfo'
import {
  getComponentDependencyData,
  useComponentValidationProvider,
  type ComponentDependencyDataBag
} from 'modules/core/ComponentValidation'
import { useFlaggedWorkingCopy } from 'modules/core/WorkingCopyProvider/effects/useFlaggedWorkingCopy'
import { FreeformTextareaEditor } from 'modules/ParameterEditors/components/FreeformTextareaEditor/FreeformTextareaEditor'
import { SQLEditor } from 'modules/ParameterEditors/components/SQLEditor/SQLEditor'

import { StorageEditorType } from '../../types'
import { BashEditor } from '../BashEditor/BashEditor'
import { DynamicUrlEditor } from '../DynamicUrlEditor/DynamicUrlEditor'
import { GridEditor } from '../GridEditor/GridEditor'
import { JoinEditor, MultiExpressionsEditor } from '../MultiExpressionsEditor'
import MultiOptionSelector from '../MultiOptionSelector/MultiOptionSelector'
import { MultiPropertiesEditor } from '../MultiPropertiesEditor/MultiPropertiesEditor'
import { OAuthEditor } from '../OAuthEditor/OAuthEditor'
import { PythonEditor } from '../PythonEditor/PythonEditor'
import { SecretReferenceEditor } from '../SecretReferenceEditor/SecretReferenceEditor'
import { SemiStructuredNestedDataPickerEditor } from '../SemiStructuredNestedDataPickerEditor/SemiStructuredNestedDataPickerEditor'
import {
  SetGridVariablesEditor,
  type HelperData
} from '../SetGridVariablesEditor/SetGridVariablesEditor'
import { SQLAdvancedEditor } from '../SQLAdvancedEditor/SQLAdvancedEditor'
import { StructuredNestedDataPickerEditor } from '../StructuredNestedDataPickerEditor/StructuredNestedDataPickerEditor'
import { UrlEditor } from '../UrlEditor/UrlEditor'
import { InlineParameterContent } from './components/InlineParameterContent'
import { InlineParameterHeader } from './components/InlineParameterHeader'
import classes from './ModalTriggerEditor.module.scss'

export interface ModalTriggerProps {
  inputId: string
  value: string[]
  hasError?: boolean
  errorText?: string
  elements?: ElementCollection
  editorType: EditorType
  parameter: ComponentParameter
  parameterName: string
  showLabel?: boolean
  optionalLabelText?: string
  onEdit: (value: ElementCollection, editorColumns?: EditorColumn[]) => void
  onBlur?: (value: ElementCollection, editorColumns?: EditorColumn[]) => void
  onEditorError?: (error?: ProblemDetails) => void
  updateElementsWithEditorColumnData?: (
    editorColumns: EditorColumn[]
  ) => ElementCollection
  compact?: boolean
  staticLabelColor?: boolean
}

interface EditorState {
  editor: ReactNode
  modalSize?: ModalProps['size']
  isSupportedEditor?: boolean
  hasNoOptions?: boolean
  displayInline?: boolean
}

const batch1Editors = [
  EditorType.OAUTH,
  EditorType.SECRET_REFERENCE,
  EditorType.SQL_EDITOR
]

export const ModalTriggerEditor = ({
  editorType,
  parameter,
  parameterName,
  onEdit,
  onBlur,
  onEditorError,
  elements = {},
  value,
  hasError = false,
  errorText,
  showLabel = false,
  optionalLabelText,
  updateElementsWithEditorColumnData,
  compact,
  staticLabelColor,
  ...props
}: ModalTriggerProps) => {
  const { t } = useTranslation()
  const [showEditor, setShowEditor] = useState(false)
  const [isQueryEnabled, setIsQueryEnabled] = useState(false)
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { job } = useFlaggedWorkingCopy()
  const { enableMoveEditorsToPanelBatch1 } = useFlags()
  const { openInlineEditor, closeInlineEditor } = useParameterEditorState()
  const {
    componentSummaryId,
    componentMetadata: componentMetaData,
    componentInstanceId,
    componentName
  } = useActiveComponentInfo()
  const { track: trackLDMetric, flush: flushLDMetrics } = useLDClient()

  const {
    isLoading: isParameterListLoading,
    isError,
    data: options,
    helperData,
    error: problemDetail,
    refetch
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: isQueryEnabled
  })

  const { isValidatingJob } = useComponentValidationProvider()
  const isLoading = isValidatingJob || isParameterListLoading

  const { validationQueryCache } = useComponentValidationProvider()
  // currently only used by the MultiExpressionEditor, all editors could receive this object.
  // It contains all the validation cache results that are inputs to this component
  let componentInputCache: ComponentDependencyDataBag = { cache: {} }

  const isInlineEditorVisible = () => {
    const enabledEditors = []

    if (enableMoveEditorsToPanelBatch1) {
      enabledEditors.push(...batch1Editors)
    }

    return enabledEditors.includes(editorType)
  }

  const displayInline = isInlineEditorVisible()

  if (job) {
    componentInputCache = getComponentDependencyData(
      job,
      componentInstanceId,
      validationQueryCache
    )
  }

  const closeEditor = useCallback(() => {
    heap.track('Parameter Editor Modal Closed', {
      componentName,
      editorType,
      parameterName,
      inlineParameterEditor: displayInline
    })
    setShowEditor(false)
    closeInlineEditor()
  }, [
    closeInlineEditor,
    componentName,
    displayInline,
    editorType,
    parameterName
  ])

  const onDone = useCallback(
    async (editedValue: ElementCollection) => {
      // it is accepted that these conditions do not necessarily mean the S3 Storage URL editor is displayed
      if (
        (componentSummaryId === excelQueryComponentId ||
          componentSummaryId === dataTransferObjectComponentId) &&
        parameter.resourceID === 'storageUrl' &&
        (editorType === EditorType.S3_URL ||
          editorType === EditorType.DYNAMIC_URL_EDITOR)
      ) {
        trackLDMetric('designer-s3-storage-url-modal-saved')
        await flushLDMetrics()
      }
      heap.track('Parameter Editor Modal Saved', {
        componentName,
        editorType,
        parameterName,
        inlineParameterEditor: displayInline
      })
      if (!displayInline) {
        closeEditor()
      }

      onEdit(editedValue, options)
      if (onBlur) {
        onBlur(editedValue, options)
      }
      clearToasts()
    },
    [
      componentSummaryId,
      parameter.resourceID,
      editorType,
      componentName,
      parameterName,
      displayInline,
      onEdit,
      options,
      onBlur,
      clearToasts,
      trackLDMetric,
      flushLDMetrics,
      closeEditor
    ]
  )

  const onDoneSingleValue = useCallback(
    (val: string[] | string) => {
      onDone(createHomogeneousElements(val, parameter?.dataType))
      clearToasts()
    },
    [onDone, clearToasts, parameter]
  )

  useEffect(() => {
    if (onEditorError) {
      onEditorError(isError && problemDetail ? problemDetail : undefined)
    }

    if (isError && problemDetail && showEditor) {
      makeToast({
        type: 'warning',
        title: problemDetail.title,
        message: problemDetail.detail ?? ''
      })
    }
  }, [
    isError,
    showEditor,
    problemDetail,
    makeToast,
    clearToasts,
    onEditorError
  ])

  const editorElements = updateElementsWithEditorColumnData
    ? updateElementsWithEditorColumnData(options)
    : elements

  const getEditor = (): EditorState => {
    switch (editorType) {
      case EditorType.MULTI_OPTION_SELECTOR:
        return {
          editor: (
            <MultiOptionSelector
              editorColumns={options}
              elements={editorElements}
              parameter={parameter}
              parameterName={parameterName}
              value={value}
              onDoneSingleValue={onDoneSingleValue}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.BLOB_URL:
      case EditorType.BLOB_URL_FOLDER_ONLY:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.AZURE}
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.FREETEXT_MULTI_LINE:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <FreeformTextareaEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.SQL_EDITOR:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <SQLEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              displayInline={displayInline}
            />
          )
        }
      case EditorType.SQL_EDITOR_ADVANCED:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <SQLAdvancedEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.BASH:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <BashEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.PYTHON:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <PythonEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.S3_URL:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.S3}
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.GCS_URL:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.GCS}
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.GCS_URL_FOLDER_ONLY:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.GCS}
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              value={value}
              lookupType={LookUpType.DYNAMIC_GCS_FOLDERS}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.SECRET_REFERENCE:
        return {
          editor: (
            <SecretReferenceEditor
              value={value}
              editorColumns={options}
              parameterName={parameterName}
              parameterId={parameter.dplID}
              onDone={onDoneSingleValue}
              displayInline={displayInline}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.GRID_EDITOR:
        return {
          editor: (
            <GridEditor
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              problemDetail={problemDetail}
              disallowDuplicates={
                isModularFlexOrCustom(componentSummaryId) &&
                [headerParamsParameter, uriParamsParameter].includes(
                  parameter.dplID
                )
              }
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.JOIN_EDITOR:
        return {
          editor: (
            <JoinEditor
              editorColumns={options}
              helperData={helperData}
              parameterName={parameterName}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              problemDetail={problemDetail}
              useTableAlias={true}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.OPTIMISE_EDITOR:
        return {
          editor: (
            <JoinEditor
              editorColumns={options}
              helperData={helperData}
              parameterName={parameterName}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              problemDetail={problemDetail}
              useTableAlias={false}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.MULTI_EXPRESSIONS_EDITOR:
        return {
          editor: (
            <MultiExpressionsEditor
              editorColumns={options}
              parameterName={parameterName}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              componentInputDepBag={componentInputCache}
              useTableAlias={true}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.MULTI_PROPERTIES_EDITOR:
        return {
          editor: (
            <MultiPropertiesEditor
              editorColumns={options}
              parameterName={parameterName}
              parameter={parameter}
              componentMetadata={componentMetaData}
              componentInstanceId={componentInstanceId}
              componentSummaryId={componentSummaryId}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.NESTED_DATA_PICKER_SF:
      case EditorType.SEMI_STRUCTURED_NESTED_DATA_PICKER:
        return {
          isSupportedEditor: true,
          editor: (
            <SemiStructuredNestedDataPickerEditor
              editorColumns={options}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              metadata={componentMetaData}
              componentId={componentSummaryId}
              parameter={parameter}
              parameterName={parameterName}
              componentInputCache={componentInputCache}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.NESTED_DATA_PICKER:
        return {
          isSupportedEditor: true,
          editor: (
            <StructuredNestedDataPickerEditor
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              componentId={componentSummaryId}
              metadata={componentMetaData}
              parameter={parameter}
              parameterName={parameterName}
              editorColumns={options}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.SCALAR_JOB_VARIABLE_OVERRIDE:
        return {
          editor: (
            <GridEditor
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
              onDone={onDone}
              elements={editorElements}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.OAUTH:
        return {
          editor: (
            <OAuthEditor
              value={value}
              editorColumns={options}
              parameterName={parameterName}
              onDone={onDoneSingleValue}
              displayInline={displayInline}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.GRID_JOB_VARIABLE_OVERRIDE:
        return (() => {
          return {
            editor: (
              <SetGridVariablesEditor
                helperData={helperData as HelperData}
                parameterName={parameterName}
                /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                onDone={onDone}
                elements={editorElements}
                {...props}
              />
            ),
            modalSize: 'mid-large'
          }
        })()
      case EditorType.DYNAMIC_URL_EDITOR:
        return {
          editor: (
            <DynamicUrlEditor
              value={value}
              editorColumns={options}
              parameterName={parameterName}
              parameter={parameter}
              onDone={onDoneSingleValue}
              componentMetaData={componentMetaData}
              componentId={componentInstanceId}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      default:
        return {
          isSupportedEditor: false,
          editor: (
            <Typography
              className={classes.EditorNotSupported}
              format="bcm"
              as="h2"
              id="parameter-overlay-title"
            >
              {t('parameterEditor.editorNotSupported')}
            </Typography>
          )
        }
    }
  }

  const {
    editor,
    isSupportedEditor = true,
    modalSize,
    hasNoOptions
  } = getEditor()

  const onTriggerClick = () => {
    heap.track('Parameter Editor Modal Opened', {
      componentName,
      editorType,
      parameterName,
      inlineParameterEditor: displayInline
    })
    setShowEditor(true)
    if (displayInline) {
      openInlineEditor()
    }
    if (isQueryEnabled && parameter.lookupType) {
      refetch({ throwOnError: false })
    }
    setIsQueryEnabled(isSupportedEditor && !hasNoOptions)
  }

  return (
    <>
      {showLabel && (
        <label
          className={classnames(classes.ModalTriggerEditor__WizardButtonLabel, {
            [classes['ModalTriggerEditor__WizardButtonLabel--Error']]:
              hasError && !staticLabelColor,
            [classes['ModalTriggerEditor__WizardButtonLabel--Compact']]: compact
          })}
        >
          <Typography as="span" weight="bold" format="bcs">
            {parameterName}
          </Typography>
          {optionalLabelText ? (
            <Typography
              as="span"
              format="bcs"
              className={classes.ModalTriggerEditor__Optional}
            >
              {`(${t('common.optional')})`}
            </Typography>
          ) : null}
        </label>
      )}

      <button
        className={classnames(classes.ModalTriggerEditor__WizardButton, {
          [classes['ModalTriggerEditor__WizardButton--Compact']]: compact,
          [classes.ModalTriggerEditor__ButtonError]: hasError
        })}
        type="button"
        data-testid={`modal-trigger-inputid-${props.inputId}`}
        data-tracker-id={`modal-trigger-${props.inputId}`}
        aria-label={t('translation:parameterEditor.triggerLabel', {
          parameterName
        })}
        onClick={onTriggerClick}
      >
        <Typography
          aria-hidden
          className={classes.ModalTriggerEditor__Value}
          format="bcs"
          as="span"
        >
          {value.join(', ').substring(0, 100).trim()}
        </Typography>
        <Icon.Cog className={classes.ModalTriggerEditor__Icon} />
      </button>
      <Typography className="u-visually-hidden" format="bcs">
        {value.join(', ')}
      </Typography>

      {!!errorText && (
        <div
          className={classnames(classes.ModalTriggerEditor__WizardButtonError, {
            [classes['ModalTriggerEditor__WizardButtonError--Compact']]: compact
          })}
        >
          <Icon.Error
            className={classnames(classes.ModalTriggerEditor__ErrorIcon, {
              [classes['ModalTriggerEditor__ErrorIcon--Compact']]: compact
            })}
          />
          <Typography as="span" format="bcs">
            {errorText}
          </Typography>
        </div>
      )}

      {showEditor && !displayInline && (
        <ParameterOverlay
          size={isQueryEnabled && isLoading ? 'default' : modalSize}
          setShowEditor={setShowEditor}
          isLoading={isQueryEnabled && isLoading}
          componentName={componentName}
          editorType={editorType}
          parameterName={parameterName}
        >
          <ErrorBoundary
            fallback={
              <>
                <ParameterOverlayHeader title={parameterName} />
                <EmptyPlaceholder type="error">
                  {t('parameterOverlay.error')}
                </EmptyPlaceholder>
              </>
            }
          >
            {editor}
          </ErrorBoundary>
        </ParameterOverlay>
      )}

      {showEditor && displayInline && (
        <Portal>
          <InlineParameterHeader
            title={parameterName}
            closeInlineEditor={closeEditor}
          />
          <InlineParameterContent>{editor}</InlineParameterContent>
        </Portal>
      )}
    </>
  )
}
