import {
  type ActivityItem,
  type PipelineStatus
} from 'api/hooks/useEosClient/types/eos'
import { State, type RunTaskStatus } from 'api/hooks/useGetRunTasks/types'

function mapStatusToState(status: PipelineStatus): State {
  switch (status) {
    case 'SUCCESS':
      return State.Success
    case 'FAILED':
      return State.Failed
    case 'CANCELLING':
      return State.Cancelling
    case 'CANCELLED':
      return State.Cancelled
    case 'SKIPPED':
      return State.Skipped
    default:
      return State.Running
  }
}

export function mapActivityItemToRunTaskStatus(
  source: ActivityItem
): RunTaskStatus {
  return {
    id: source.id,
    versionName: '1',
    jobName: source.definition.pipelineName,
    environmentName: source.context.environment,
    enqueuedTime: Date.parse(source.result.startedAt),
    startTime: Date.parse(source.result.startedAt),
    endTime: source.result.finishedAt
      ? Date.parse(source.result.finishedAt)
      : null,
    flowInstanceId: source.id,
    state: mapStatusToState(source.result.status)
  }
}
