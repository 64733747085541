import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tab, Tabs } from '@matillion/component-library'

import {
  ComponentEditorTabType,
  type ComponentMetadata
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { useFlags } from 'hooks/useFlags'

import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'

import { ComponentTabContent } from '../ComponentTabContent/ComponentTabContent'
import classes from './ComponentParameterTabs.module.scss'

interface ComponentParameterTabsProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
  onBlur: (params: EditedParameter) => void
}

export const ComponentParameterTabs: FC<ComponentParameterTabsProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  onBlur
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'componentProperties.tabs'
  })

  const { enablePostProcessingTab } = useFlags()

  const tabs = [
    ComponentEditorTabType.PROPERTIES,
    ...(enablePostProcessingTab ? [ComponentEditorTabType.POSTPROCESSING] : [])
  ].filter((tabType) => componentMetadata.editorTabs.includes(tabType))

  if (tabs.length > 1) {
    return (
      <Tabs
        className={classes.ComponentParameterTabs__Container}
        listClassName={classes.ComponentParameterTabs__List}
        contentClassName={classes.ComponentParameterTabs__Content}
      >
        {tabs.map((tabType) => {
          const tabKey = tabType.toLowerCase()

          return (
            <Tab
              key={`comnponent-properties-tab-${tabKey}`}
              title={t(`titles.${tabKey}`)}
              data-testid={`wizard-tab-component-properties-${tabKey}`}
            >
              <ComponentTabContent
                tabType={tabType}
                componentInstance={componentInstance}
                componentMetadata={componentMetadata}
                componentSummaryId={componentSummaryId}
                onEdit={onEdit}
                onBlur={onBlur}
              />
            </Tab>
          )
        })}
      </Tabs>
    )
  }

  return (
    <ComponentTabContent
      tabType={tabs[0]}
      componentInstance={componentInstance}
      componentMetadata={componentMetadata}
      componentSummaryId={componentSummaryId}
      onEdit={onEdit}
      onBlur={onBlur}
    />
  )
}
