import { createElement, type FC, type SVGProps } from 'react'

import { Icon } from '@matillion/component-library'

import { type PipelineStatus } from 'api/hooks/useEosClient/types/eos'
import { State } from 'api/hooks/useGetRunTasks/types'

import { ReactComponent as CancelledSvg } from 'assets/cancelled-small.svg'
import { ReactComponent as ErrorSvg } from 'assets/failed-small.svg'
import { ReactComponent as SuccessSvg } from 'assets/success-small.svg'

const SkippedSvg: FC<SVGProps<SVGSVGElement>> = (props) => (
  <SuccessSvg {...props} style={{ filter: 'grayscale(100%)', opacity: 0.5 }} />
)

const StateIconMapper = {
  [State.Failed]: ErrorSvg,
  [State.Success]: SuccessSvg,
  [State.Running]: Icon.Loading,
  [State.Queued]: Icon.Loading,
  [State.Cancelled]: CancelledSvg,
  [State.Cancelling]: CancelledSvg,
  [State.Skipped]: SkippedSvg,
  UNKNOWN: Icon.Warning
}

export interface StateIconProps extends SVGProps<SVGSVGElement> {
  status: State | PipelineStatus | undefined
}

const StateIcon: FC<StateIconProps> = ({ status, ...props }) => {
  const IconComponent =
    status && StateIconMapper[status] ? StateIconMapper[status] : Icon.Warning
  return createElement(IconComponent, props)
}

export default StateIcon
