import { createContext } from 'react'

import type { AxiosInstance } from 'axios'

export const ApiClientContext = createContext<ApiClients | undefined>(undefined)

export interface ApiClients {
  designerClient: AxiosInstance
  copilotClient: AxiosInstance
  projectExplorerClient: AxiosInstance
}
