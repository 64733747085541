import { useState, type FC } from 'react'

import classNames from 'classnames'

import { type NestingAwareStep } from 'api/hooks/useEosClient/types/eos'

import CellMessage from '../components/CellMessage/CellMessage'
import { CellTypography } from '../components/CellTypography'
import { DateTime } from '../components/DateTime'
import Guidelines from '../components/Guidelines/Guidelines'
import { Cell, Row } from '../components/PipelineTable'
import { calcDuration } from '../utils/calcDuration'
import NestedPipelineSteps from './NestedPipelineSteps'
import PipelineNameCell from './PipelineNameCell'
import classes from './StepRow.module.scss'

interface StepRowProps {
  step: NestingAwareStep
  depth: number
  pipelineName?: string
  rowClassName?: string
  cellClassName?: string
  isRoot: boolean
  isLast: boolean
  treeLines: boolean[]
  pollInterval?: number
}

const StepRow: FC<StepRowProps> = ({
  step,
  depth,
  pipelineName,
  rowClassName,
  cellClassName,
  isRoot,
  isLast,
  treeLines,
  pollInterval
}) => {
  const {
    result: { startedAt, finishedAt, rowCount, message, status },
    nestedPipeline,
    nestedSteps,
    name
  } = step
  const hasNestedSteps = nestedSteps.length > 0
  const [isExpanded, setIsExpanded] = useState(hasNestedSteps)

  return (
    <>
      <Row
        className={classNames(rowClassName, classes.StepRow)}
        data-testid="step-row"
      >
        <Cell className={classNames(cellClassName, classes.StepRow__FirstCell)}>
          <Guidelines
            depth={depth}
            treeLines={treeLines}
            isRoot={isRoot}
            isLast={isLast}
            isExpanded={isExpanded}
          />
          <PipelineNameCell
            depth={depth}
            isExpanded={isExpanded}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
            hasNestedStuff={!!nestedPipeline || hasNestedSteps}
            pipelineName={pipelineName ?? ''}
            status={status}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={name} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={<DateTime timestamp={startedAt} />} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography
            text={calcDuration(startedAt, finishedAt ?? '')}
            subtext={<DateTime timestamp={finishedAt} />}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography
            text={(rowCount || rowCount === 0) && String(rowCount)}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellMessage value={message} stepId={step.id} />
        </Cell>
      </Row>

      {isExpanded && nestedPipeline && (
        <NestedPipelineSteps
          rowClassName={rowClassName}
          nestedPipeline={nestedPipeline}
          parentDepth={depth}
          treeLines={treeLines}
          parentIsLast={isLast}
          pollInterval={pollInterval}
        />
      )}

      {isExpanded &&
        hasNestedSteps &&
        nestedSteps.map((nestedStep, index) => {
          return (
            <StepRow
              key={nestedStep.id}
              step={nestedStep}
              depth={depth + 1}
              pipelineName={pipelineName}
              isRoot={false}
              isLast={index === nestedSteps.length - 1}
              treeLines={[...treeLines, !isLast]}
              rowClassName={rowClassName}
              pollInterval={pollInterval}
            />
          )
        })}
    </>
  )
}

export default StepRow
