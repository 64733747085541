import { type AxiosInstance } from 'axios'

import { type ActivityItem } from 'api/hooks/useEosClient/types/eos'

export const getRootPipelineActivity = async (
  client: AxiosInstance,
  pipelineId: string
): Promise<ActivityItem> => {
  const { data } = await client.get<ActivityItem>(
    `/v1/activity/pipeline/${pipelineId}`
  )
  return data
}
