import { useInfiniteQuery } from '@tanstack/react-query'

import useEosClient from 'api/hooks/useEosClient/useEosClient'

import { getSteps, type StepsResponse } from './__handlers__/getSteps'
import { collateSteps } from './utils/collateSteps'

const getNextPage = (lastPage: StepsResponse) => lastPage

export const useGetStepsInfinite = (
  pipelineId: string,
  refetchInterval?: number,
  refetchOnMount?: boolean | 'always'
) => {
  const client = useEosClient()
  const { data, isFetching, fetchNextPage, isFetchingNextPage, isError } =
    useInfiniteQuery(
      ['steps', pipelineId],
      async ({ pageParam }) => {
        const prevPage = pageParam as StepsResponse | undefined

        const offset =
          typeof prevPage?.offset === 'number'
            ? prevPage.offset + prevPage.limit
            : 0
        const limit = prevPage?.limit ?? undefined

        return getSteps(client, pipelineId, offset, limit)
      },
      {
        getNextPageParam: getNextPage,
        refetchInterval,
        refetchOnMount
      }
    )

  const allSteps = data?.pages.flatMap((page) => page?.results)

  const collatedSteps = allSteps ? collateSteps(allSteps) : undefined

  const hasMore = Boolean(data?.pages[data.pages.length - 1]?._links.next)

  const fetchNext = () => {
    if (hasMore && !isFetching) {
      void fetchNextPage()
    }
  }

  return {
    steps: collatedSteps,
    isFetching,
    isRefreshing: isFetching && !isFetchingNextPage,
    isPaginating: isFetchingNextPage,
    hasMore,
    isError,
    fetchNextPage: fetchNext
  }
}
