import { useMemo, type PropsWithChildren } from 'react'

import { useCopilotApiClient } from '../copilot/useCopilotApiClient/useCopilotApiClient'
import { useDesignerApiClient } from '../useDesignerApiClient/useDesignerApiClient'
import { useProjectExplorerApiClient } from '../useProjectExplorerApiClient/useProjectExplorerApiClient'
import { ApiClientContext } from './ApiClientContext'

export const ApiClientProvider = ({ children }: PropsWithChildren) => {
  const designerClient = useDesignerApiClient()
  const copilotClient = useCopilotApiClient()
  const projectExplorerClient = useProjectExplorerApiClient()

  const value = useMemo(() => {
    return {
      designerClient,
      copilotClient,
      projectExplorerClient
    }
  }, [designerClient, copilotClient, projectExplorerClient])

  return (
    <ApiClientContext.Provider value={value}>
      {children}
    </ApiClientContext.Provider>
  )
}
